/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION,
} from '../../util/types';

import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemVoucherPremiumMaybe from './LineItemVoucherPremiumMaybe';
import LineItemTotalVoucher from './LineItemTotalVoucher';

import { currenciesHelper as c } from '../../helpers/currencies';

import css from './OrderBreakdown.module.css';

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems;
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const isVoucherPayoutFunc = lineItems => {
    const voucherAllocationLineItem = lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION
    );
    return (
      voucherAllocationLineItem &&
      voucherAllocationLineItem.lineTotal &&
      voucherAllocationLineItem.lineTotal.amount !== 0
    );
  };
  const isVoucherPayout = isVoucherPayoutFunc(lineItems);

  const classes = classNames(rootClassName || css.root, className);

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  const { protectedData } = transaction.attributes;
  let currentCurrency = c.get();

  const customerData = protectedData?.customerMulticurrencyData;
  const transactionCurrency = protectedData?.customerMulticurrencyData;

  const customerConvertedItemPrice = customerData?.itemPrice?.find(
    p => p.currency === currentCurrency
  )?.amount;
  const customerConvertedShippingFee = customerData?.shippingFee?.find(
    p => p.currency === currentCurrency
  )?.amount;
  const customerTotalConverted = customerConvertedItemPrice + customerConvertedShippingFee;

  const providerData = protectedData?.providerMulticurrencyData;
  // const providerData = {};

  const providerConvertedItemPrice = providerData?.itemPrice?.find(
    p => p.currency === currentCurrency
  )?.amount;
  const providerConvertedShippingFee = providerData?.shippingFee?.find(
    p => p.currency === currentCurrency
  )?.amount;

  const providerConvertedCommission = providerData?.commission?.find(
    p => p.currency === currentCurrency
  )?.amount;
  const providerConvertedPremiumVoucher =
    providerData?.premiumVoucher?.find(p => p.currency === currentCurrency)?.amount || null;
  const providerTotalConverted = providerConvertedItemPrice - providerConvertedCommission;
  const providerTotalVoucherConverted =
    providerConvertedItemPrice - providerConvertedCommission + providerConvertedPremiumVoucher;
  const providerRevundConverted = providerConvertedItemPrice + providerConvertedShippingFee;

  const isProviderRefund = transaction.attributes.payoutTotal.amount === 0;

  return (
    <div className={classes}>
      {/* TODO:
      - Remove LineItemUnknownItemsMaybe
      - Implement a new component LineItemVoucherPremiumMaybe
        - show only if line-item/provider-voucher-allocation != 0.0
        - it is equal to X% * provider-voucher-allocation
      - Implement a new component LineItemTotalVoucher
        - show instead of LineItemTotalPrice if line-item/provider-voucher-allocation != 0.0
        - it is equal to (100% + X%) * provider-voucher-allocation
        - Note: Implement LineItemProviderCommissionRefundMaybe
       */}

      <LineItemBasePriceMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        isProvider={isProvider}
        isCustomer={isCustomer}
        currentCurrency={currentCurrency}
        customerConvertedItemPrice={customerConvertedItemPrice}
        providerConvertedItemPrice={providerConvertedItemPrice}
      />

      {isCustomer && (
        <LineItemShippingFeeMaybe
          lineItems={lineItems}
          intl={intl}
          isProvider={isProvider}
          isCustomer={isCustomer}
          currentCurrency={currentCurrency}
          customerConvertedShippingFee={customerConvertedShippingFee}
        />
      )}

      {isCustomer && <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />}
      {/* <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} /> */}

      {/* <LineItemSubTotalMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        userRole={userRole}
        intl={intl}
        marketplaceCurrency={currency}
      /> */}
      <LineItemRefundMaybe
        lineItems={lineItems}
        intl={intl}
        marketplaceCurrency={currency}
        isProvider={isProvider}
        isCustomer={isCustomer}
        currentCurrency={currentCurrency}
        customerTotalConverted={customerTotalConverted}
        providerRevundConverted={providerRevundConverted}
      />

      <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
        currentCurrency={currentCurrency}
        providerConvertedCommission={providerConvertedCommission}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
        currentCurrency={currentCurrency}
        providerConvertedCommission={providerConvertedCommission}
      />

      {!isProviderRefund && (
        <LineItemVoucherPremiumMaybe
          lineItems={lineItems}
          isProvider={isProvider}
          isVoucherPayout={isVoucherPayout}
          intl={intl}
          currentCurrency={currentCurrency}
          providerConvertedPremiumVoucher={providerConvertedPremiumVoucher}
        />
      )}

      {isVoucherPayout && isProvider ? (
        <LineItemTotalVoucher
          lineItems={lineItems}
          transaction={transaction}
          isVoucherPayout={isVoucherPayout}
          intl={intl}
          currentCurrency={currentCurrency}
          providerTotalVoucherConverted={providerTotalVoucherConverted}
          isProviderRefund={isProviderRefund}
        />
      ) : (
        <LineItemTotalPrice
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
          isCustomer={isCustomer}
          currentCurrency={currentCurrency}
          customerTotalConverted={customerTotalConverted}
          providerTotalConverted={providerTotalConverted}
        />
      )}

      {/* {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null} */}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
