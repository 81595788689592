import en from './base/en/main.json';
import de from './base/de/main.json';
import brandEn from './brandSpecific/en/main.json';
import brandDe from './brandSpecific/de/main.json';

// Convert to the format expected by the app
const formatTranslations = (translations, locale) => {
  return Object.keys(translations).reduce((acc, key) => {
    acc[`${key}.${locale}`] = translations[key];
    return acc;
  }, {});
};

// Merge base translations with brand-specific overrides
const mergeTranslations = (base, brandSpecific) => ({
  ...base,
  ...brandSpecific,
});

export const defaultMessages = {
  ...formatTranslations(mergeTranslations(en, brandEn), 'en'),
  ...formatTranslations(mergeTranslations(de, brandDe), 'de'),
};

export default defaultMessages;
