import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemShippingFeeMaybe = props => {
  const {
    lineItems,
    intl,
    isProvider,
    isCustomer,
    currentCurrency,
    customerConvertedShippingFee,
  } = props;

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  if (!shippingFeeLineItem) return null;

  const shippingFee = formatMoney(intl, shippingFeeLineItem.lineTotal);
  const convertedShippingFee = !!customerConvertedShippingFee
    ? formatMoney(intl, new Money(customerConvertedShippingFee, currentCurrency))
    : null;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.shippingFee" />
      </span>
      {isCustomer && <span className={css.itemValue}>{convertedShippingFee || shippingFee}</span>}
      {isProvider && <span className={css.itemValue}>{shippingFee}</span>}
    </div>
  );
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
