import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSettingsDots.module.css';

const IconSettingsDots = props => {
  const { rootClassName, className, withMargin } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootMargin]: withMargin,
  });
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#4A4A4F"
        d="M2.5 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M11.5 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M7 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
      ></path>
    </svg>
  );
};

IconSettingsDots.defaultProps = {
  rootClassName: null,
  className: null,
};

IconSettingsDots.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSettingsDots;
