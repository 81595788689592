import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getConversionRates } from '../../util/api';

const REACT_APP_CURRENCY_SWITCH_ENABLED = process.env.REACT_APP_CURRENCY_SWITCH_ENABLED === 'true';

// * Action types *

// SellHomePage
export const UPLOAD_REQUEST = 'app/SellHomePage/UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'app/SellHomePage/UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'app/SellHomePage/UPLOAD_ERROR';
export const CLEAR_ITEMS = 'app/SellHomePage/CLEAR_ITEMS';

// SellSelectPage
export const FETCH_SELL_PAGE_DATA_REQUEST = 'app/SellPage/FETCH_SELL_PAGE_DATA_REQUEST';
export const FETCH_SELL_PAGE_DATA_SUCCESS = 'app/SellPage/FETCH_SELL_PAGE_DATA_SUCCESS';
export const FETCH_SELL_PAGE_DATA_ERROR = 'app/SellPage/FETCH_SELL_PAGE_DATA_ERROR';
export const SET_SELECTED_ITEM = 'app/SellPage/SET_SELECTED_ITEM';

// SellAddDetailsPage
export const SAVE_UPLOADED_ITEM_DETAILS = 'app/SellPage/SAVE_UPLOADED_ITEM_DETAILS';

// SellReviewPage
export const FETCH_SELL_REVIEW_PAGE_DATA_REQUEST =
  'app/SellPage/FETCH_SELL_REVIEW_PAGE_DATA_REQUEST';
export const FETCH_SELL_REVIEW_PAGE_DATA_SUCCESS =
  'app/SellPage/FETCH_SELL_REVIEW_PAGE_DATA_SUCCESS';
export const FETCH_SELL_REVIEW_PAGE_DATA_ERROR = 'app/SellPage/FETCH_SELL_REVIEW_PAGE_DATA_ERROR';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/SellPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/SellPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/SellPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FETCH_CONVERSION_RATES = 'app/SellPage/FETCH_CONVERSION_RATES';

// * Action creators *
const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

// SellHomePage
export const uploadRequest = () => ({ type: UPLOAD_REQUEST });
export const uploadSuccess = payload => ({ type: UPLOAD_SUCCESS, payload });
export const uploadError = error => ({ type: UPLOAD_ERROR, error });
export const clearItems = () => ({ type: CLEAR_ITEMS });

// SellSelectPage
export const fetchSellPageDataRequest = () => ({ type: FETCH_SELL_PAGE_DATA_REQUEST });
export const fetchSellPageDataSuccess = response => ({
  type: FETCH_SELL_PAGE_DATA_SUCCESS,
  payload: response,
});
export const fetchSellPageDataError = e => ({
  type: FETCH_SELL_PAGE_DATA_ERROR,
  error: true,
  payload: e,
});
export const setSelectedItem = selectedItem => ({
  type: SET_SELECTED_ITEM,
  payload: selectedItem,
});

// SellAddDetailsPage
export const saveUploadedItemDetails = payload => ({
  type: SAVE_UPLOADED_ITEM_DETAILS,
  payload,
});

// SellReviewPage
export const fetchSellReviewPageRequest = () => ({ type: FETCH_SELL_REVIEW_PAGE_DATA_REQUEST });
export const fetchSellReviewPageSuccess = () => ({ type: FETCH_SELL_REVIEW_PAGE_DATA_SUCCESS });
export const fetchSellReviewPageError = e => ({
  type: FETCH_SELL_REVIEW_PAGE_DATA_ERROR,
  error: true,
  payload: e,
});
export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

// * Reducer *
const initialState = {
  uploadInProgress: false,
  uploadError: null,
  similarityResponseData: null,
  idItem: null,
  fetchInProgress: false,
  fetchError: null,
  items: [],
  selectedItem: null,
  itemDetails: {},
  reviewPageRequestInProgress: false,
  reviewPageRequestError: null,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  uploadedImageDownloadURL: null,
  conversionRates: null,
};

export default function sellPageReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;
  switch (type) {
    // SellHomePage
    case UPLOAD_REQUEST:
      return { ...state, uploadInProgress: true, uploadError: null };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadInProgress: false,
        similarityResponseData: payload.similarityResponseData,
        idItem: payload.idItem,
        uploadedImageDownloadURL: payload.uploadedImageDownloadURL,
      };
    case UPLOAD_ERROR:
      return { ...state, uploadInProgress: false, uploadError: error };
    case CLEAR_ITEMS:
      return { ...state, items: [] };

    // SellSelectPage
    case FETCH_SELL_PAGE_DATA_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_SELL_PAGE_DATA_SUCCESS:
      return { ...state, fetchInProgress: false, items: payload };
    case FETCH_SELL_PAGE_DATA_ERROR:
      return { ...state, fetchInProgress: false, fetchError: error };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: payload };

    // SellAddDetailsPage
    case SAVE_UPLOADED_ITEM_DETAILS:
      return { ...state, itemDetails: payload };

    // SellReviewPage
    case FETCH_SELL_REVIEW_PAGE_DATA_REQUEST:
      return { ...state, reviewPageRequestInProgress: true, reviewPageRequestError: null };
    case FETCH_SELL_REVIEW_PAGE_DATA_SUCCESS:
      return { ...state, reviewPageRequestInProgress: false };
    case FETCH_SELL_REVIEW_PAGE_DATA_ERROR:
      return { ...state, reviewPageRequestInProgress: false, reviewPageRequestError: error };
    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case FETCH_CONVERSION_RATES:
      return { ...state, conversionRates: payload };

    default:
      return state;
  }
}

export const fetchConversionRates = payload => ({
  type: FETCH_CONVERSION_RATES,
  payload,
});

// ================ Thunk ================ //
export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  if (REACT_APP_CURRENCY_SWITCH_ENABLED) {
    await getConversionRates({})
      .then(response => {
        dispatch(fetchConversionRates(response));
        return response;
      })
      .catch(e => {
        console.log(e);
      });
  }

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
};
