import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION, propTypes } from '../../util/types';
import { voucherPremiumPercentage } from '../../config/configPayout';
import Pricing from '../../util/pricingFunction';

const { types } = require('sharetribe-flex-sdk');

const { Money } = types;

import css from './OrderBreakdown.module.css';

const LineItemVoucherPremiumMaybe = props => {
  const {
    lineItems,
    isProvider,
    isVoucherPayout,
    intl,
    currentCurrency,
    providerConvertedPremiumVoucher,
  } = props;

  const voucherAllocationLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION && !item.reversal
  );
  if (!voucherAllocationLineItem) return null; // Early return if no voucher allocation line item found

  const basePayout = -voucherAllocationLineItem.lineTotal.amount / 100; // sign reverse the negative voucherAllocation line item; convert to EUR
  const voucherPremiumAmount = parseFloat(
    Pricing.calculatePayoutPremium(basePayout, voucherPremiumPercentage, true, 5)
  );
  const voucherPremiumAmountProc = voucherPremiumAmount * 100; // convert back to cents
  const voucherPremium = new Money(
    voucherPremiumAmountProc,
    voucherAllocationLineItem.lineTotal.currency
  );

  const voucherPremiumConverted =
    !!providerConvertedPremiumVoucher &&
    formatMoney(intl, new Money(providerConvertedPremiumVoucher, currentCurrency));

  return isProvider && isVoucherPayout ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="OrderBreakdown.voucherPremium"
          values={{ voucherPremiumPercentage }}
        />
      </span>
      <span className={css.itemValue}>
        {voucherPremiumConverted || formatMoney(intl, voucherPremium)}
      </span>
    </div>
  ) : null;
};

LineItemVoucherPremiumMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isVoucherPayout: bool,
  intl: intlShape.isRequired,
};

export default LineItemVoucherPremiumMaybe;
