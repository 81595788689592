export const conditionOptions = [
  { value: 'new_with_tags', label: 'New with tags' },
  { value: 'like_new', label: 'Like new' },
  { value: 'great', label: 'Great' },
  { value: 'good', label: 'Good' },
];

export const countryOptions = [
  { value: 'Germany', label: 'Germany' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'France', label: 'France' },
];

export const countryCodeMap = {
  Germany: 'DE',
  Austria: 'AT',
  Netherlands: 'NL',
  Belgium: 'BE',
  France: 'FR',
};
